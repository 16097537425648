import { Injectable } from '@angular/core';
import moment from 'moment';
import { AuditNode } from 'src/app/shared/models/audit-node.model';
import { Audit } from 'src/app/shared/models/audit.model';
import { v4 } from 'uuid';
import { Template } from '../models/template.model';

@Injectable({ providedIn: 'root' })
export class CreateAuditService {
  public createAudit(template: Template, prefillData = []) {
    // const account = this._auth.currentUser;
    const copyNewAuditNodeFromTemplateNode = (templateNode: any, parentId: string) => {
      const auditNode = new AuditNode();
      auditNode.id = v4();
      auditNode._id = auditNode.id;
      auditNode.archived = false;
      auditNode.parentId = parentId;

      if (templateNode.isSystemNode) {
        switch (templateNode.systemNode) {
          case 1:
            auditNode.responses = [{ value: getDocumentNo() }];
            break;
          case 4:
            auditNode.responses = [{ value: moment.utc().toISOString() }];
            break;
          // case 5:
          //   auditNode.responses = [
          //     {
          //       value: account ? `${account.firstName} ${account.lastName}` : ''
          //     }
          //   ];
          //   break;
        }
      }
      auditNode.color = templateNode.color;
      auditNode.description = templateNode.description;
      auditNode.mandatory = templateNode.mandatory;
      auditNode.nodeType_Id = templateNode.nodeType_Id;
      auditNode.options = templateNode.options;
      auditNode.isSystemNode = templateNode.isSystemNode;
      auditNode.systemNode = templateNode.systemNode;
      auditNode.failedResponse = templateNode.failedResponse;
      auditNode.templateNodeId = templateNode._id;
      auditNode.selectables = templateNode.selectables || [];
      auditNode.title = templateNode.title;
      auditNode.unit = templateNode.unit;

      const data = prefillData.find((f) => f._id === templateNode._id);
      if (data) {
        auditNode.responses = [{ value: data.value }];
      }

      return auditNode;
    };

    const audit = new Audit();
    audit._id = v4();
    audit.archived = false;
    audit.templateId = template._id;
    audit.displayType = template.defaultAuditDisplayType;
    audit.auditNodes = [];
    audit.createdAt = moment.utc().toDate();
    audit.updatedAt = moment.utc().toDate();
    audit.entityType = template.entityTypes[0];

    // if (account) {
    //   audit.createdBy = account._id;
    //   audit.updatedBy = account._id;
    // }

    const numberFormat = template.documentNoFormat;

    copyNewAuditNodeFromTemplateNodeRec(template.templateNodes, audit.auditNodes);

    audit.auditNodes.forEach((auditNode) => {
      if (auditNode.children) {
        auditNode.children = flatten(auditNode.children);
        auditNode.children.forEach((childAuditNode) => {
          switch (childAuditNode.nodeType_Id) {
            case 'condition':
              childAuditNode.conditionTriggerId = childAuditNode.parentId;
              flatten(childAuditNode.children ? childAuditNode.children : []).forEach((subNode) => {
                subNode.conditionId = childAuditNode.id;
              });
              break;
            case 'therapy_progress':
              childAuditNode.responses = [
                { value: [{ date: 'date', auditId: 'auditId', auditNodeId: 'auditNodeId' }] },
                {
                  value: [
                    {
                      labels: ['01.01.2023', '04.01.2023', '10.01.2023'],
                      lengthData: [15, 8, undefined],
                      widthData: [10, 9, 6],
                      depthData: [2.5, 1.5, undefined],
                    },
                  ],
                },
              ];
              break;
            case 'articleData':
              childAuditNode.responses = [
                {
                  value: [
                    {
                      article: {
                        name: 'artixleX',
                        description: 'description',
                        manufacturerNumber: 'manufacturerNumber',
                        customerNumber: 'customerNumber',
                        medicalAidPositionNumber: 'medicalAidPositionNumber',
                        ean: 'ean',
                        additionalDescription: 'additionalDescription',
                        manufacturer: 'companyX',
                        size: '100 g',
                        pharmacyPrice: '11,11',
                        listPrice: '1,99',
                        fixedPrice: '1,99',
                        unavailableTo: '2022-06-28',
                        unavailableFrom: '2022-06-27',
                        delayedTo: '2022-06-29',
                        substituteArticleCustomerNumber: 'substituteArticleCustomerNumber',
                        pharmacyOnly: true,
                      },
                      unit: 'Stk',
                      quantity: 3,
                    },
                    {
                      article: {
                        manufacturerNumber: '1234',
                        customerNumber: '25524',
                        name: 'articleZ',
                        manufacturer: 'manufacturerZ',
                        description: 'description',
                        pharmacyPrice: '3,01',
                      },
                      unit: 'Ktn',
                      quantity: 10,
                    },
                    {
                      article: {
                        name: 'artixleY',
                        description: 'description',
                        manufacturerNumber: 'manufacturerNumber',
                        customerNumber: 'customerNumber',
                        medicalAidPositionNumber: 'medicalAidPositionNumber',
                        ean: 'ean',
                        additionalDescription: 'additionalDescription',
                        manufacturer: 'manufacturerY',
                        size: '2 L',
                        pharmacyPrice: '12,01',
                        listPrice: '2,99',
                        fixedPrice: '3,00',
                        unavailableTo: '2022-06-28',
                        unavailableFrom: '2022-06-27',
                        delayedTo: '2022-06-29',
                        substituteArticleCustomerNumber: 'substituteArticleCustomerNumber',
                        pharmacyOnly: true,
                      },
                      unit: 'Ktn',
                      quantity: 1,
                    },
                  ],
                },
              ];
              break;

            case 'addArticleElement':
              childAuditNode.responses = [
                {
                  value: [
                    {
                      // these properties are part of the article model
                      _id: '035065df-0f3b-4380-ab48-2ef2d8da43ba',
                      articleId: '1a49b059-2a1d-42e5-9fa4-b7aad3127d12',
                      manufacturerNumber: 'EC20450',
                      pcn: '4424381',
                      customerNumber: '23736',
                      medicalAidPositionNumber: '',
                      name: 'CENAMAN Dickungsmittel Pulver',
                      manufacturer: 'easycare Research GmbH ',
                      description: '210g Dose',
                      additionalDescription: 'Dose',
                      pharmacyOnly: true,
                      size: '210 g',
                      therapyIds: [2],
                      billableTherapies: [2],
                      packagingUnits: [
                        {
                          pcn: '4424381',
                          quantity: 1,
                          unit: 'Stk',
                          defaultPackagingUnit: true,
                          _id: 'b599a05a-365a-42de-927f-7d40dc472ca6',
                        },
                        {
                          pcn: '4424398',
                          quantity: 6,
                          unit: 'Ktn',
                          defaultPackagingUnit: false,
                          _id: '468ecb16-cb6e-44df-ae2e-b0906ede9107',
                        },
                      ],
                      purchaseRating: 0,
                      pharmacyPrice: '9,10',
                      assortmentType: 2,
                      status: 1,
                      inventoryType: 2,
                      stars: 5,
                      archived: true,
                      assistGroups: 'BEA10',
                      timestamp: '2021-05-31T12:32:20.275Z',
                      unavailableFrom: null,
                      productGroupId: '9f782d59-f00c-4824-b2f1-7e7426f065db',
                      attributes: [],
                      insuranceFixedPrice: [],
                      unavailableTo: null,
                      updatedAt: '2021-05-31T12:32:20.275Z',
                      updatedBy: '3f881c5d-a40f-46bc-80a9-8dc1bad3c0e6',
                      // these properties are not part of the article model
                      packagingUnit: {
                        pcn: '4424381',
                        quantity: 1,
                        unit: 'Stk',
                        defaultPackagingUnit: true,
                        _id: 'b599a05a-365a-42de-927f-7d40dc472ca6',
                      },
                      quantity: 1,
                    },
                  ],
                },
              ];
            // case "repeater":
            //   flatten(
            //     childAuditNode.children ? childAuditNode.children : []
            //   ).forEach(subNode => {
            //     subNode.repeaterId = childAuditNode.id;
            //   });
            //   break;
            default:
          }
          // if (childAuditNode.nodeType_Id !== "repeater") {
          //   delete childAuditNode.children;
          // }
        });
      }
    });
    template.documentNo++;

    function flatten(data) {
      const result = [];
      function flattenRec(_data, _result) {
        _data.forEach((element) => {
          if (!_result.find((f) => f.id === element.id)) {
            _result.push(element);
          }
          if (element.nodeType_Id === 'repeater') {
            flattenRec(element.children ? element.children : [], element.children);
          } else {
            flattenRec(element.children ? element.children : [], _result);
          }
        });
      }
      flattenRec(data, result);
      return result;
    }

    function getDocumentNo(): string {
      if (!numberFormat || numberFormat === null) {
        return template.documentNo.toString().padStart(5, '0');
      }

      if (numberFormat.indexOf('[number') === -1) {
        return numberFormat;
      }
      if (numberFormat.indexOf('[number]') !== -1) {
        return numberFormat.replace('[number]', template.documentNo.toString().padStart(5, '0'));
      }
      if (numberFormat.indexOf('[number=') !== -1) {
        const r1 = numberFormat.match(/\[(.*?)\]/);
        const d = parseInt(r1[1].match(/\=(.*?)$/)[1].replace(/\D/g, ''), 10);
        if (d && !isNaN(d)) {
          return numberFormat.replace(
            r1[0],
            template.documentNo.toLocaleString('en-US', {
              minimumIntegerDigits: d,
              useGrouping: false,
            })
          );
        }
        return numberFormat.replace('[number]', template.documentNo.toString().padStart(5, '0'));
      }
      return template.documentNo.toString().padStart(5, '0');
    }

    function copyNewAuditNodeFromTemplateNodeRec(templateNodes, auditNodes, parentId?: string) {
      templateNodes.forEach((templateNode) => {
        const auditNode = copyNewAuditNodeFromTemplateNode(templateNode, parentId);
        if (templateNode.children) {
          copyNewAuditNodeFromTemplateNodeRec(templateNode.children, auditNode.children, auditNode.id);
        }
        auditNodes.push(auditNode);
      });
    }

    return Promise.resolve(audit);
  }
}
