import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AuditReportService } from 'src/app/shared/services/audit-report.service';
import { CreateAuditService } from 'src/app/shared/services/create-audit.service';
import { ReportService } from 'src/app/shared/services/report.service';

import { Template } from '../../../shared/models/template.model';

@Component({
  selector: 'app-template-report',
  templateUrl: './template-report.component.html',
  styleUrls: ['./template-report.component.scss'],
})
export class TemplateReportComponent implements OnInit, OnChanges {
  @Input() public template: Template;
  public showSpinner: boolean;
  @ViewChild('designer', { static: true })
  private _designer: any;
  public designer: Stimulsoft.Designer.StiDesigner;
  private _designerRendered: boolean;

  constructor(
    private _reportService: ReportService,
    private _createAuditService: CreateAuditService,
    private _auditReportService: AuditReportService
  ) {}

  ngOnInit() {
    Stimulsoft.Base.StiLicense.key =
      '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkJ2or/7Baexggyb/CoJTWw5tbdDhaAFoQFSrso+HywMU0omd' +
      '9t+u8H2eWI0jjl79hJUyhwKtQwD4mfoKgB1BhaL9nuTZOyJKe1imrCTa/Y1jeAKJhiwyD7tZJv9VpRKqmOCriy76jE' +
      '0bf9ZYHgF7/XLfbropeJtTNxgj9q0BLLhFX/dsGTSnehzBPBEdwerwPOYkJz1MyFLBrbr+VrrUFAG9L8wOqjjbJS3D' +
      'kYRth7hfuyHjnJXylg4PTIUar8CU7jT0JzJNpM+JT33NbFpcbM82/ZUf6/WLAeb2GqiocZmXKhtTNFxIUBhvkHAk3v' +
      'mMVZcOVA//qEK5pAXO2U8iGdwhHsigfOS/neR+Te6HA0SzVSTSKorE+tUrriGcIQvjqGR3QhHzDp2iwK7Eo8J14lxO' +
      'ADXBZoKlEW8DFaoRgJqOPseYQZ5V5zd6TSx8bR4i7chp4nyFUYauvbhGDLsOjLP1r6ddu0CBNxwma1LNIGTWIwZZ1B' +
      '7MiZoAag2iLgl3N6JYXJmgka5DEnbaWXWwyc';
    const options = new Stimulsoft.Designer.StiDesignerOptions();
    options.appearance.fullScreenMode = true;
    options.viewerOptions.appearance.reportDisplayMode = 2;

    const toolbarOptions = new Stimulsoft.Designer.StiToolbarOptions();
    toolbarOptions.showSaveButton = false;
    options.toolbar = toolbarOptions;

    this.designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
  }

  public save(currentTab?: number) {
    if (this.designer.report == null) {
      return;
    }
    if (this.designer.report.isModified === false) {
      return;
    }
    // if (currentTab === 2 && this.designer.report.getComponentsCount() < 2) {
    //   Stimulsoft.System.StiError.showError(
    //     'Du versuchst einen leeren Bericht zu speichern. Bericht wird nicht gespeichert!',
    //     true
    //   );
    //   return;
    // }
    const jsonStr = this.designer.report.saveToJsonString();
    if ((this.template as any).report) {
      this._reportService.patch(this.template._id, { report: jsonStr });
    } else {
      this._reportService.create({ _id: this.template._id, report: jsonStr });
      (this.template as any).report = true;
    }
    this.designer.report.isModified = false;
  }

  async ngOnChanges() {
    if (this.template) {
      // init report with undefined so we dont save accidentally an empty report
      this.designer.report = undefined;
      // this.showSpinner = true;
      const report = new Stimulsoft.Report.StiReport();

      if ((this.template as any).report) {
        const reportEntity = await this._reportService.get(this.template._id);
        if (reportEntity) {
          report.load(reportEntity.report);
        }
      }
      const data = await this.prepareData();
      const dataSet = new Stimulsoft.System.Data.DataSet('swodoc');
      dataSet.readJson(data);
      report.regData(dataSet.dataSetName, '', dataSet);
      report.dictionary.synchronize();
      this.designer.report = report;
      // this.showSpinner = false;
    }
  }

  // gets called by template-detail.component.ts
  public async renderDesigner() {
    if (this._designerRendered) {
      return;
    }
    this.showSpinner = true;
    this.designer.renderHtml(this._designer.nativeElement);
    this._designerRendered = true;
    this.showSpinner = false;
  }

  private async prepareData() {
    const audit = await this._createAuditService.createAudit(this.template);
    return this._auditReportService.prepareDataForReport(audit);
  }
}
