import { MetadataGeneratable } from '@alberta/swodoc-document-generator';

export class MetadataGenerator implements MetadataGeneratable {
  generateXML(): string {
    throw new Error('Method not implemented.');
  }
  generateXSD(): string {
    return `<xsd:element name="meta" minOccurs="1" maxOccurs="1">
              <xsd:complexType>
                  <xsd:sequence>
                      <xsd:element name="dokumententyp" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="debitorennummer" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="belegnummer" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="vorgangsnummer" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="datum" type="xsd:date" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="firma" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="belegart" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="ImageNowFooter" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="OMSEmailAddress" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="version" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="dokumentenId" type="xsd:string" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="sendToOcr" type="xsd:boolean" minOccurs="1" maxOccurs="1"></xsd:element>
                      <xsd:element name="isPdf" type="xsd:boolean" minOccurs="1" maxOccurs="1"></xsd:element>
                  </xsd:sequence>
              </xsd:complexType>
          </xsd:element>`;
  }
}
