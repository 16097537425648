import { Component, Input, OnChanges } from '@angular/core';
import { Config } from 'src/app/shared/models/config.model';
import { ConfigService } from 'src/app/shared/services/config.service';

import { AlbertaMatchField } from '../../../shared/models/alberta-match-field.model';
import { MatchField } from '../../../shared/models/match-field.model';
import { TemplateViewModel } from '../../../shared/models/template.view.model';
import {
  AlbertaInstitutionsMatchFields,
  AlbertaPatientsMatchFields,
  AlbertaPrescriptionRequestMatchFields,
  AlbertaReturnDeliveryMatchFields,
} from '../../../shared/services/alberta-match-fields.service';
import { MatchService } from '../../../shared/services/match.service';

@Component({
  selector: 'app-template-match',
  templateUrl: './template-match.component.html',
  styleUrls: ['./template-match.component.scss'],
})
export class TemplateMatchComponent implements OnChanges {
  @Input() public template: TemplateViewModel;
  public showSpinner: boolean;
  public templateNodes;

  public matchFields: MatchField[];
  public albertaMatchFields = [];

  constructor(private _matchService: MatchService, private _configService: ConfigService) {}

  async ngOnChanges() {
    this.matchFields = undefined;
    this.templateNodes = undefined;
    const tenantConfigPatientAttributes = [];

    const tenantConfigs = (await this._configService.find()) as Config[];
    if (tenantConfigs && tenantConfigs.length) {
      const tenantConfig = tenantConfigs[0] as Config;
      if (
        tenantConfig &&
        tenantConfig.patientData &&
        tenantConfig.patientData.attributeConfig &&
        tenantConfig.patientData.attributeConfig.length > 0
      ) {
        tenantConfigPatientAttributes.push(
          ...tenantConfig.patientData.attributeConfig.map((item, index) => ({
            _id: 2000 + index,
            path: `additionalAttributes.${item.name}`,
            title: `Patient - Zusatzfeld - ${item.name}`,
          }))
        );
      }
    }
    if (
      (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'prescription') ||
      (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'medicalOrder')
    ) {
      this.albertaMatchFields = [
        ...AlbertaPatientsMatchFields,
        ...tenantConfigPatientAttributes,
        ...AlbertaPrescriptionRequestMatchFields,
      ];
    } else if (
      (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'patient') ||
      (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'crossTherapy') ||
      (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'service')
    ) {
      this.albertaMatchFields = [...AlbertaPatientsMatchFields, ...tenantConfigPatientAttributes];
    } else if (
      this.template &&
      this.template.entityTypes.length === 1 &&
      this.template.entityTypes[0] === 'returnDelivery'
    ) {
      this.albertaMatchFields = [...AlbertaPatientsMatchFields, ...AlbertaReturnDeliveryMatchFields];
    } else {
      this.albertaMatchFields = AlbertaInstitutionsMatchFields;
    }
    this.albertaMatchFields.sort((a, b) => a.title.localeCompare(b.title));
    if (this.template) {
      this.showSpinner = true;
      this.templateNodes = this.buildTemplateNodesArray();
      if (this.template.match) {
        const match = await this._matchService.get(this.template._id);
        if (match) {
          this.matchFields = match.fields;
        }
      }
      this.showSpinner = false;
    }
  }
  compareFn(c1: AlbertaMatchField, c2: AlbertaMatchField): boolean {
    return c1 && c2 ? c1._id === c2._id : c1 === c2;
  }
  addMatchField() {
    this.matchFields = this.matchFields || [];
    this.matchFields.push({ swodoc: undefined, alberta: undefined });
  }

  removeMatchField(field: MatchField) {
    this.matchFields = this.matchFields.filter((f) => f !== field);
  }

  itemDisabled(id: string) {
    if (this.matchFields && this.matchFields.length > 0) {
      return this.matchFields.find((f) => f.swodoc === id) !== undefined;
    }
  }

  async save() {
    if (this.matchFields) {
      if (this.matchFields.length === 0) {
        return await this._matchService.remove(this.template._id);
      }
      if (this.template.match) {
        await this._matchService.patch(this.template._id, {
          fields: this.matchFields,
        });
      } else {
        await this._matchService.create({
          _id: this.template._id,
          fields: this.matchFields,
        });
      }
    }
  }

  private buildTemplateNodesArray() {
    const templateNode = { template: this.template.title, sections: [] };
    this.template.templateNodes.forEach((section) => {
      const templateNodeSection = { title: section.title, nodes: [] };
      this.buildTemplateNodesArrayRec(section, templateNodeSection);
      templateNode.sections.push(templateNodeSection);
    });
    return templateNode;
  }

  private buildTemplateNodesArrayRec(node, templateNodeSection) {
    if (node.children && node.children !== null) {
      this.buildTemplateNodesArrayRec(node.children, templateNodeSection);
    } else {
      node.forEach((child) => {
        if (child.nodeType_Id === 'text' || child.nodeType_Id === 'textarea' || child.nodeType_Id === 'date') {
          templateNodeSection.nodes.push({
            _id: child._id,
            title: child.title !== '' ? child.title : '- no name -',
            name: child._name,
            type: child.nodeType_Id,
          });
        }
        if (child.children && child.children !== null && child.children.length) {
          this.buildTemplateNodesArrayRec(child.children, templateNodeSection);
        }
      });
    }
  }
}
