import { DocumentGenerator } from '@alberta/swodoc-document-generator';
import { Injectable } from '@angular/core';

import { EmptyAssetLoader } from './asset-loader';
import { KeyValueGenerator } from './key-value-Generator';

@Injectable({
  providedIn: 'root',
})
export class AuditReportService {
  public async prepareDataForReport(audit) {
    const documentGenerator = new DocumentGenerator(new EmptyAssetLoader(), new KeyValueGenerator());
    const data = await documentGenerator.generate(audit, { allConitionsTrue: true });
    return data;
  }
}
