import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Template } from '../../shared/models/template.model';
import { BasicInfoFormMode } from '../template-basic-info-form/template-basic-info-form.component';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-create-edit-template-dialog',
  templateUrl: './create-edit-template-dialog.component.html',
  styleUrls: ['./create-edit-template-dialog.component.scss'],
})
export class CreateEditTemplateDialogComponent {
  public template: Template;
  public formIsValid: boolean;
  public busy: boolean;
  public error: string;
  public showInstituionEntityTypesSelect = false;
  public templateEditorMode: BasicInfoFormMode;

  constructor(
    public dialogRef: MatDialogRef<CreateEditTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { template: Template; entityTypes?: string[] },
    private _templateService: TemplateService
  ) {
    this.init();
  }

  public async createOrEdit(): Promise<void> {
    this.busy = true;
    try {
      let template: Template | Template[];
      if (this.template._id) {
        const {
          title,
          description,
          type,
          therapyId,
          therapyTypeId,
          dmsFieldmapping,
          dmsDocumentType,
          dmsAccessList,
          dmsModule,
          payer,
          templateTag,
          entityTypes,
          groupingText,
          sendEmailOnComplete,
          disableReport,
          sendToOcr,
          patientEntity,
        } = this.template;
        template = await this._templateService.patch(this.template._id, {
          title: title || null,
          description: description || null,
          type: type || null,
          therapyId: therapyId || null,
          therapyTypeId: therapyTypeId || null,
          dmsFieldmapping: dmsFieldmapping || null,
          dmsDocumentType: dmsDocumentType || null,
          dmsAccessList: dmsAccessList || null,
          dmsModule: dmsModule || null,
          payer: payer || null,
          templateTag: templateTag || null,
          entityTypes: entityTypes || null,
          groupingText: groupingText || null,
          sendEmailOnComplete: sendEmailOnComplete || null,
          disableReport: disableReport || null,
          sendToOcr: sendToOcr || null,
          patientEntity: patientEntity || null,
        });
      } else {
        this.template.draft = true;
        template = await this._templateService.create(this.template);
      }
      this.dialogRef.close(template);
    } catch (e) {
      this.error = e.message;
    } finally {
      this.busy = false;
    }
  }

  private init(): void {
    if (this.data.template) {
      this.template = this.data.template;
      this.templateEditorMode = this.getFormMode(this.template.entityTypes[0]);
    } else {
      this.template = new Template();
      if (this.data.entityTypes.length === 1) {
        this.templateEditorMode = this.getFormMode(this.data.entityTypes[0]);
        this.template.entityTypes = this.data.entityTypes;
      } else {
        this.templateEditorMode = BasicInfoFormMode.institution;
      }
    }
  }

  private getFormMode(entityType: string): BasicInfoFormMode {
    switch (entityType) {
      case 'patient':
        return BasicInfoFormMode.patient;
      case 'integratedCare':
      case 'singleOrder':
      case 'directOrder':
      case 'returnDelivery':
        return BasicInfoFormMode.careDocuments;
      case 'crossTherapy':
        return BasicInfoFormMode.crossTherapy;
      case 'prescription':
        return BasicInfoFormMode.prescription;
      case 'medicalOrder':
        return BasicInfoFormMode.medicalOrder;
      case 'service':
        return BasicInfoFormMode.service;
      default:
        return BasicInfoFormMode.institution;
    }
  }
}
