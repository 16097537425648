import { IUser } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { Application } from '@feathersjs/feathers';
import { ReplaySubject } from 'rxjs';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _app: Application;
  private _isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public currentUser: IUser;
  public isAuthenticated = this._isAuthenticatedSubject.asObservable();

  constructor(feathers: FeathersService) {
    this._app = feathers.app;
    this._authenticate();
  }

  private async _authenticate() {
    try {
      const result = await this._app.reAuthenticate(true);
      this.currentUser = result.user;
      this._isAuthenticatedSubject.next(true);
    } catch (e) {
      this._isAuthenticatedSubject.next(false);
    }
  }

  public async login(username: string, password: string) {
    const options = {
      strategy: 'fusionAuth',
      email: username.toLowerCase(),
      password,
      applicationId: '5e9e8812-b866-48d2-9b15-8a96fdb92473',
    };
    const result = await this._app.authenticate(options);
    this.currentUser = result.user;
    this._isAuthenticatedSubject.next(true);
  }

  public async logout() {
    await this._app.logout();
    this._isAuthenticatedSubject.next(false);
  }

  public async getToken() {
    return this._app.authentication.getAccessToken();
  }
}
