import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatchService } from 'src/app/shared/services/match.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { v4 } from 'uuid';

import { Template } from '../../shared/models/template.model';
import { TemplateViewModel } from '../../shared/models/template.view.model';
import { Tenant } from '../../shared/models/tenant.model';
import { BasicInfoFormMode } from '../template-basic-info-form/template-basic-info-form.component';
import { TemplateService } from '../template.service';
import { Certificate } from 'crypto';

@Component({
  selector: 'app-copy-template-dialog',
  templateUrl: './copy-template-dialog.component.html',
  styleUrls: ['./copy-template-dialog.component.scss'],
})
export class CopyTemplateDialogComponent implements OnInit {
  template: TemplateViewModel;
  duplicateReport: boolean;
  duplicateMatch: boolean;
  error: string;
  busy: boolean;
  formIsValid: boolean;
  public templateEditorMode: BasicInfoFormMode;

  constructor(
    public dialogRef: MatDialogRef<CopyTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { tenant: Tenant; template: Template },
    private _templateService: TemplateService,
    // private _copyService: CopyService
    private _reportService: ReportService,
    private _matchService: MatchService
  ) {
    if (!this.data || !this.data.template) {
      throw new Error('Template is missing');
    }

    this.template = Object.assign(new TemplateViewModel(), this.data.template);
    if (this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'patient') {
      this.templateEditorMode = BasicInfoFormMode.patient;
    } else {
      this.templateEditorMode = BasicInfoFormMode.institution;
    }
  }

  ngOnInit() {
    this.duplicateReport = this.template.report !== undefined;
    this.duplicateMatch = this.template.match !== undefined;
    this.template.title += ' (Copy)';
  }

  async copy() {
    this.busy = true;
    try {
      const origId = this.template._id;

      const templateCopy: Template = { ...this.template };
      delete (templateCopy as TemplateViewModel).match;
      delete (templateCopy as TemplateViewModel).report;
      delete (templateCopy as any).selected;

      templateCopy._id = v4();
      templateCopy.originTemplateId = origId;
      templateCopy.draft = true;

      const result = await this._templateService.create(templateCopy);
      if (result) {
        let copyPromises: Promise<void>[] = [];
        if (this.duplicateReport) {
          copyPromises.push(this.copyReport(templateCopy.originTemplateId, templateCopy._id));
        }
        if (this.duplicateMatch) {
          copyPromises.push(this.copyMatch(templateCopy.originTemplateId, templateCopy._id));
        }

        let copyErrors = await Promise.all(copyPromises.map((p) => p.catch((e) => e)));
        copyErrors = copyErrors.filter((e) => e !== undefined);

        if (copyErrors.length > 0) {
          let combinedMessage = '';
          for (const error of copyErrors) {
            console.log(this.error);
            if (error.message) {
              combinedMessage = combinedMessage + error.message;
            }
          }
          throw new Error(combinedMessage);
        }
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.error = e.message;
    } finally {
      this.busy = false;
    }
  }

  private async copyReport(oldId: string, newId: string): Promise<void> {
    const reportEntity = await this._reportService.get(oldId, undefined, { noCaching: true });
    reportEntity._id = newId;
    await this._reportService.create(reportEntity);
  }
  private async copyMatch(oldId: string, newId: string): Promise<void> {
    const matchEntity = await this._matchService.get(oldId, undefined, {
      noCaching: true,
    });
    matchEntity._id = newId;
    await this._matchService.create(matchEntity);
  }
}
