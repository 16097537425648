<div [ngClass]="{ fullscreen: fullscreen, isSaving: isSaving }" class="wrapper">
  <app-spinner *ngIf="isSaving" class="spinner-overlay"></app-spinner>
  <div class="mat-elevation-z10 top-button-bar">
    <div class="buttons">
      <button mat-button color="primary" (click)="save()"><mat-icon>save</mat-icon>Speichern</button>
      <button mat-button (click)="exportXSD()"><mat-icon>download</mat-icon>Export XSD</button>
      <button mat-button color="accent" (click)="close()"><mat-icon>cancel</mat-icon>Schließen</button>
    </div>
    <h2 class="spacer title">{{ template?.title }}</h2>
    <button mat-icon-button (click)="toggleFullscreen()">
      <mat-icon>{{ fullscreen ? 'unfold_less' : 'unfold_more' }}</mat-icon>
    </button>
  </div>
  <mat-tab-group color="accent" #tabs>
    <mat-tab label="Vorlage">
      <div class="wrapper">
        <iframe #swodocIframe [src]="templateUrl != 'about:blank' && sanitizer.bypassSecurityTrustResourceUrl(templateUrl)"></iframe>
      </div>
    </mat-tab>
    <mat-tab label="Zuweisungen">
      <app-template-match [template]="template" #match></app-template-match>
    </mat-tab>
    <mat-tab label="Bericht">
      <app-template-report [template]="template" #report class="app-template-report"></app-template-report>
    </mat-tab>
  </mat-tab-group>
</div>
