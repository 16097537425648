import { AlbertaMatchField } from 'src/app/shared/models/alberta-match-field.model';

export const AlbertaPatientsMatchFields: AlbertaMatchField[] = [
  {
    _id: 1,
    path: 'firstName',
    title: 'Patient - Vorname',
  },
  {
    _id: 2,
    path: 'lastName',
    title: 'Patient - Nachname',
  },
  {
    _id: 3,
    path: 'birthday',
    title: 'Patient - Geburtsdatum',
  },
  {
    _id: 4,
    path: 'customerId',
    title: 'Patient - KundenNr',
  },
  {
    _id: 5,
    path: 'address',
    title: 'Patient - Strasse HausNr.',
  },
  {
    _id: 6,
    modelName: 'postalCode',
    path: 'postalCodeId',
    modelPath: 'postalCode',
    title: 'Patient - PLZ',
  },
  {
    _id: 7,
    modelName: 'postalCode',
    path: 'postalCodeId',
    modelPath: 'city',
    title: 'Patient - Ort',
  },
  {
    _id: 8,
    path: 'payer.numberOfInsured',
    title: 'Patient - Versichertennummer',
  },
  {
    _id: 9,
    modelName: 'payer',
    path: 'payer.payerId',
    modelPath: 'name',
    title: 'Patient - Krankenkasse',
  },
  {
    _id: 10,
    path: 'gender',
    title: 'Patient - Geschlecht',
    lookup: {
      0: 'Unbekannt',
      1: 'Weiblich',
      2: 'Männlich',
      3: 'Sonstige',
    },
  },

  {
    _id: 11,
    path: 'phone',
    title: 'Patient - Telefon',
  },
  {
    _id: 12,
    path: 'mobilePhone',
    title: 'Patient - Mobile',
  },
  {
    _id: 13,
    path: 'email',
    title: 'Patient - E-Mail',
  },
  {
    _id: 14,
    path: 'payer.payerType',
    title: 'Patient - priv/ges Versicherung',
    lookup: {
      0: 'unbekannt',
      1: 'gesetzlich',
      2: 'privat',
      3: 'Berufsgenossenschaft',
      4: 'Selbstzahler',
    },
  },
  {
    _id: 15,
    path: 'payer.copaymentToDate',
    title: 'Patient - Zuzahlungsbefreit bis',
  },
  {
    _id: 16,
    path: 'gender',
    title: 'Patient - Anrede',
    lookup: {
      0: '',
      1: 'Frau',
      2: 'Herr',
      3: '',
    },
  },
  {
    _id: 17,
    entity: 'audit',
    path: 'woundLocation',
    title: 'Dokument - Wundlokalisation',
  },
  {
    _id: 18,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'name',
    title: 'Pflegedienst - Name',
  },
  {
    _id: 19,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'address',
    title: 'Pflegedienst - Strasse',
  },
  {
    _id: 20,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'postalCode',
    title: 'Pflegedienst - PLZ',
  },
  {
    _id: 21,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'city',
    title: 'Pflegedienst - Ort',
  },
  {
    _id: 22,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'name',
    title: 'Pflegeheim - Name',
  },
  {
    _id: 23,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'address',
    title: 'Pflegeheim - Strasse',
  },
  {
    _id: 24,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'postalCode',
    title: 'Pflegeheim - PLZ',
  },
  {
    _id: 25,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'city',
    title: 'Pflegeheim - Ort',
  },
  {
    _id: 26,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'title',
    title: 'Arzt - Titel',
  },
  {
    _id: 27,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'firstName',
    title: 'Arzt - Vorname',
  },
  {
    _id: 28,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'lastName',
    title: 'Arzt - Nachname',
  },
  {
    _id: 29,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'address',
    title: 'Arzt - Strasse',
  },
  {
    _id: 30,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'postalCode',
    title: 'Arzt - PLZ',
  },
  {
    _id: 31,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'city',
    title: 'Arzt - Ort',
  },
  {
    _id: 32,
    entity: 'audit',
    path: 'therapy',
    title: 'Dokument - Therapie',
  },
  {
    _id: 33,
    entity: 'audit',
    path: 'therapyType',
    title: 'Dokument - Therapietyp',
  },
  {
    _id: 34,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'name',
    title: 'Apotheke - Name',
  },
  {
    _id: 35,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'address',
    title: 'Apotheke - Strasse',
  },
  {
    _id: 36,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'postalCode',
    title: 'Apotheke - PLZ',
  },
  {
    _id: 37,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'city',
    title: 'Apotheke - Ort',
  },
  {
    _id: 38,
    entity: 'user',
    path: 'firstName',
    title: 'Benutzer - Vorname',
  },
  {
    _id: 39,
    entity: 'user',
    path: 'lastName',
    title: 'Benutzer - Nachname',
  },
  {
    _id: 40,
    entity: 'user',
    path: 'address',
    title: 'Benutzer - Strasse',
  },
  {
    _id: 41,
    entity: 'user',
    path: 'postalCode',
    title: 'Benutzer - PLZ',
  },
  {
    _id: 42,
    entity: 'user',
    path: 'phone',
    title: 'Benutzer - Telefon',
  },
  {
    _id: 43,
    entity: 'user',
    path: 'mobilePhone',
    title: 'Benutzer - Mobil',
  },
  {
    _id: 44,
    entity: 'user',
    path: 'fax',
    title: 'Benutzer - Fax',
  },
  {
    _id: 45,
    entity: 'user',
    path: 'email',
    title: 'Benutzer - EMail',
  },
  {
    _id: 46,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'phone',
    title: 'Arzt - Telefon',
  },
  {
    _id: 47,
    entity: 'patient',
    modelName: 'doctor',
    path: 'primaryDoctorId',
    modelPath: 'fax',
    title: 'Arzt - Fax',
  },
  {
    _id: 48,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'phone',
    title: 'Apotheke - Telefon',
  },
  {
    _id: 49,
    entity: 'patient',
    modelName: 'pharmacy',
    path: 'pharmacyId',
    modelPath: 'fax',
    title: 'Apotheke - Fax',
  },
  {
    _id: 50,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'phone',
    title: 'Pflegeheim - Telefon',
  },
  {
    _id: 51,
    entity: 'patient',
    modelName: 'nursingHome',
    path: 'nursingHomeId',
    modelPath: 'fax',
    title: 'Pflegeheim - Fax',
  },
  {
    _id: 52,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'phone',
    title: 'Pflegedienst - Telefon',
  },
  {
    _id: 53,
    entity: 'patient',
    modelName: 'nursingService',
    path: 'nursingServiceId',
    modelPath: 'fax',
    title: 'Pflegedienst - Fax',
  },
  {
    _id: 54,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'phone',
    title: 'Klinik - Telefon',
  },
  {
    _id: 55,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'fax',
    title: 'Klinik - Fax',
  },
  {
    _id: 71,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'name',
    title: 'Klinik - Name',
  },
  {
    _id: 72,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'address',
    title: 'Klinik - Strasse',
  },
  {
    _id: 73,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'postalCode',
    title: 'Klinik - PLZ',
  },
  {
    _id: 74,
    entity: 'patient',
    modelName: 'hospital',
    path: 'hospital.hospitalId',
    modelPath: 'city',
    title: 'Klinik - Ort',
  },
  {
    _id: 75,
    path: 'additionalAddress',
    title: 'Patient - Adresszusatz 1',
  },
  {
    _id: 76,
    path: 'additionalAddress2',
    title: 'Patient - Adresszusatz 2',
  },
  {
    _id: 77,
    path: 'resmedTenant',
    title: 'Patient - Entität',
  },
  {
    _id: 78,
    path: 'careDegree',
    title: 'Patient - Pflegegrad',
  },
  {
    _id: 79,
    path: 'calorieDemand',
    title: 'Patient - Kalorienbedarf (kcal)',
  },
  {
    _id: 80,
    entity: 'user',
    path: 'storageLocation',
    title: 'Benutzer - Lagernummer Mitarbeiter/Filiale',
  },
  {
    _id: 81,
    entity: 'user',
    path: 'salesPersonId',
    title: 'Benutzer - Verkäufercode',
  },
  {
    _id: 82,
    entity: 'audit',
    path: 'additionalInfo',
    title: 'Dokument - Zusatzinformation',
  },
  {
    _id: 83,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'firstName',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Vorname',
  },
  {
    _id: 84,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'lastName',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Nachname',
  },
  {
    _id: 85,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'type',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    lookup: {
      0: 'Unbekannt',
      1: 'Ehemann',
      2: 'Ehefrau',
      3: 'Lebensgefährte/in',
      4: 'Sohn',
      5: 'Tochter',
      6: 'Enkel',
      7: 'Enkelin',
      8: 'Urenkel',
      9: 'Urenkelin',
      10: 'Mutter',
      11: 'Vater',
      12: 'Onkel',
      13: 'Tante',
      14: 'Nichte',
      15: 'Neffe',
      16: 'Nachbarin',
      17: 'Nachbar',
      18: 'Betreuungsbüro',
      19: 'Freund',
      20: 'Schwester',
      21: 'Bruder',
      22: 'Cousin',
      23: 'Cousine',
      24: 'Großonkel',
      25: 'Großtante',
      26: 'Großnichte',
      27: 'Großneffe',
      28: 'Schwager',
      29: 'Schwägerin',
      30: 'Schwagersbruder',
      31: 'Schwagersschwester',
      32: 'Schwiegermutter',
      33: 'Schwiegervater',
      34: 'Schwiegeronkel',
      35: 'Schwiegertante',
      36: 'Schwiegerkind',
      37: 'Schwiegersohn',
      38: 'Schwiegertochter',
      39: 'Schwiegerenkel',
      40: 'Schwiegerenkelin',
      41: 'Stiefmutter',
      42: 'Stiefvater',
      43: 'Stiefschwester',
      44: 'Stiefbruder',
      45: 'Stieftochter',
      46: 'Stiefsohn',
      47: 'Stiefenkel',
      48: 'Stiefurenkel',
      49: 'Pflegesohn',
      50: 'Pflegetochter',
      51: 'Pflegemutter',
      52: 'Pflegevater',
      53: 'Halbschwester',
      54: 'Halbbruder',
      55: 'Halbonkel',
      56: 'Halbtante',
      57: 'Halbcousin',
      58: 'Halbcousine',
    },
    title: 'Gesetzlicher Betreuer - Art',
  },
  {
    _id: 86,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'gender',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    lookup: {
      0: 'Unbekannt',
      1: 'Weiblich',
      2: 'Männlich',
      3: 'Sonstige',
    },
    title: 'Gesetzlicher Betreuer - Geschlecht',
  },
  {
    _id: 87,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'title',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    lookup: {
      0: 'Unbekannt',
      1: 'Dr.',
      2: 'Prof. Dr.',
      3: 'Dipl. Ing.',
      4: 'Dipl. med.',
      5: 'Dipl. Psych.',
      6: 'Dr. Dr.',
      7: 'Dr. med.',
      8: 'Prof. Dr. Dr.',
      9: 'Prof.',
      10: 'Prof. Dr. med.',
      11: 'Rechtsanwalt',
      12: 'Rechtsanwältin',
      13: 'Schwester',
      14: 'Dr. jur.',
      15: 'Firma',
      16: 'Freifrau',
      17: 'Freiherr',
      18: 'Gemeinschaftspraxis',
      19: 'Praxis',
      20: 'Priv.-Doz. Dr. med.',
    },
    title: 'Gesetzlicher Betreuer - Titel',
  },
  {
    _id: 88,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'address',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Straße und Hausnr.',
  },
  {
    _id: 89,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'postalCodeId',
    modelName: 'postalCode',
    modelPath: 'postalCode',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - PLZ',
  },
  {
    _id: 90,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'postalCodeId',
    modelName: 'postalCode',
    modelPath: 'city',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Ort',
  },
  {
    _id: 91,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'email',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - E-Mail',
  },
  {
    _id: 92,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'phone',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Telefonnummer',
  },
  {
    _id: 93,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'mobilePhone',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Mobilfunknummer',
  },
  {
    _id: 94,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'fax',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Faxnummer',
  },
  {
    _id: 91,
    entity: 'patient',
    path: 'careGivers',
    subPath: 'comment',
    restriction: {
      path: 'role',
      equals: 'legal_carer',
    },
    title: 'Gesetzlicher Betreuer - Bemerkung',
  },
  {
    _id: 95,
    entity: 'patient',
    path: 'primaryDoctorId',
    title: 'Arzt - ID',
  },
    {
    _id: 96,
    entity: 'user',
    path: 'city',
    title: 'Benutzer - Ort',
  },
];

export const AlbertaInstitutionsMatchFields: AlbertaMatchField[] = [
  {
    _id: 56,
    path: 'name',
    title: 'Institution - Name',
  },
  {
    _id: 57,
    path: 'address',
    title: 'Institution - Adresse',
  },
  {
    _id: 58,
    path: 'postalCode',
    title: 'Institution - Postleitzahl',
  },
  {
    _id: 59,
    path: 'city',
    title: 'Institution - Stadt',
  },
  {
    _id: 60,
    path: 'phone',
    title: 'Institution - Telefon',
  },
  {
    _id: 61,
    path: 'fax',
    title: 'Institution - Fax',
  },
  {
    _id: 62,
    path: 'email',
    title: 'Institution - E-Mail',
  },
  {
    _id: 63,
    entity: 'user',
    path: 'firstName',
    title: 'Benutzer - Vorname',
  },
  {
    _id: 64,
    entity: 'user',
    path: 'lastName',
    title: 'Benutzer - Nachname',
  },
  {
    _id: 67,
    entity: 'user',
    path: 'phone',
    title: 'Benutzer - Telefon',
  },
  {
    _id: 68,
    entity: 'user',
    path: 'mobilePhone',
    title: 'Benutzer - Mobil',
  },
  {
    _id: 69,
    entity: 'user',
    path: 'fax',
    title: 'Benutzer - Fax',
  },
  {
    _id: 70,
    entity: 'user',
    path: 'email',
    title: 'Benutzer - EMail',
  },
  {
    _id: 80,
    entity: 'user',
    path: 'storageLocation',
    title: 'Benutzer - Mitarbeiterlager',
  },
];

export const AlbertaPrescriptionRequestMatchFields: AlbertaMatchField[] = [
  {
    _id: 1001,
    entity: 'prescriptionRequest',
    path: 'therapyId',
    title: 'Versorgung - Therapie',
  },
  {
    _id: 1002,
    entity: 'prescriptionRequest',
    path: 'therapyTypeId',
    title: 'Versorgung - Therapieart',
  },
  {
    _id: 1003,
    entity: 'prescriptionRequest',
    path: 'woundLocation',
    title: 'Versorgung - Wundlokalisation',
  },
];

export const AlbertaReturnDeliveryMatchFields: AlbertaMatchField[] = [
  {
    _id: 2001,
    entity: 'returnDelivery',
    path: 'returnDeliveryDate',
    title: 'Retoure - gew. Abholdatum',
  },
  {
    _id: 2002,
    entity: 'returnDelivery',
    path: 'countOfPackages',
    title: 'Retoure - Anzahl Pakete',
  },
  {
    _id: 2003,
    entity: 'returnDelivery',
    path: 'reason',
    title: 'Retoure - Retourengrund',
  },
  {
    _id: 2004,
    entity: 'returnDelivery',
    path: 'returnDeliveryAddress.city',
    title: 'Retoure - Ort',
  },
  {
    _id: 2005,
    entity: 'returnDelivery',
    path: 'returnDeliveryAddress.postalCode',
    title: 'Retoure - PLZ',
  },
  {
    _id: 2006,
    entity: 'returnDelivery',
    path: 'returnDeliveryAddress.address',
    title: 'Retoure - Adresse',
  },
  {
    _id: 2007,
    entity: 'returnDelivery',
    path: 'orderIds',
    title: 'Retoure - Lieferdatum Bestellungen',
  },
];
