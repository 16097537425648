import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-patient-entity',
  templateUrl: './patientEntity.component.html',
  styleUrls: ['./patientEntity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientEntityComponent),
      multi: true,
    },
  ],
})
export class PatientEntityComponent {
  @ViewChild('select', { static: true }) select: MatSelect;
  public entities = ['RMD', 'RMT'];

  writeValue(obj: any): void {
    this.select.value = obj;
  }
  registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.select.disabled = isDisabled;
  }
}
