import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
} from '@angular/material';
import { DmsAccessListComponent } from 'src/app/template/dms-access-list/dms-access-list.component';
import { DmsDocumentTypeComponent } from 'src/app/template/dms-document-type/dms-document-type.component';
import { DmsFieldmappingComponent } from 'src/app/template/dms-fieldmapping/dms-fieldmapping.component';
import { DmsModuleComponent } from 'src/app/template/dms-module/dms-module.component';
import { PayerComponent } from 'src/app/template/payer/payer.component';
import { UpdatedAtPipe } from 'src/app/template/template-list/updated-at.pipe';

import { SharedModule } from '../shared/shared.module';
import { CopyTemplateDialogComponent } from './copy-template-dialog/copy-template-dialog.component';
import { CreateEditTemplateDialogComponent } from './create-edit-template-dialog/create-edit-template-dialog.component';
import { PatientEntityComponent } from './patientEntity/patientEntity.component';
import { TemplateBasicInfoFormComponent } from './template-basic-info-form/template-basic-info-form.component';
import { TemplateDetailComponent } from './template-detail/template-detail.component';
import { TemplateMatchComponent } from './template-detail/template-match/template-match.component';
import { TemplateReportComponent } from './template-detail/template-report/template-report.component';
import { EntityTypePipe } from './template-list/map-entity-types.pipe';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplateMoreMenuComponent } from './template-more-menu/template-more-menu.component';
import { TherapyTypeComponent } from './therapy-type/therapy-type.component';
import { TherapyComponent } from './therapy/therapy.component';
import { TherapyPipe } from './therapy/therapy.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTabsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    TemplateListComponent,
    TemplateDetailComponent,
    TherapyPipe,
    TemplateMoreMenuComponent,
    TemplateBasicInfoFormComponent,
    CreateEditTemplateDialogComponent,
    TherapyComponent,
    TherapyTypeComponent,
    CopyTemplateDialogComponent,
    TemplateMatchComponent,
    TemplateReportComponent,
    DmsAccessListComponent,
    DmsFieldmappingComponent,
    DmsModuleComponent,
    DmsDocumentTypeComponent,
    PayerComponent,
    UpdatedAtPipe,
    EntityTypePipe,
    PatientEntityComponent,
  ],
  entryComponents: [CreateEditTemplateDialogComponent, CopyTemplateDialogComponent],
  exports: [TemplateListComponent],
})
export class TemplateModule {}
